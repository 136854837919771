<template>
  <div>
    <b-card
      no-body
    >
      <div class="card-header">
        <b-card-title>Header</b-card-title>
      </div>

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              variant="primary"
              :to="{ name: 'header-menu' }"
            >
              <span class="text-nowrap">Menú</span>
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
              <b-button
                variant="primary"
                :to="{ name: 'header-add'}"
              >
                <span class="text-nowrap">Nuevo enlace</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        :items="fetchLinks"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
        responsive="sm"
        primary-key="id"
        empty-text="No se encontraron items"
        show-empty
        striped
      >
        <!-- Column: Icon -->
        <template #cell(icon)="data">
          <app-image
            :src="data.item.icon"
            alt="icon"
            width="24px"
            height="24px"
          />
        </template>

        <!-- Column: urls -->
        <template #cell(urls)="data">
          <div class="d-flex flex-column align-items-start">
            <div
              v-for="url in data.item.urls"
              :key="url.id"
              class="px-1"
              style="margin-bottom: 4px"
            >
              {{ url.lang ? url.lang.name : '' }}:  {{ url.name }}
            </div>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          {{ data.item.status ? 'SI' : 'NO' }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <div
              v-if="data.item.loading"
              class="text-center"
            >
              <b-spinner
                :id="`banner-row-${data.item.id}-loading-icon`"
                small
              />
            </div>

            <div v-else>
              <feather-icon
                :id="`link-row-${data.item.id}-duplicate-icon`"
                icon="CopyIcon"
                size="16"
                class="mr-1 cursor-pointer"
                @click="onDuplicate(data.item)"
              />
              <b-tooltip
                title="Duplicar Banner"
                :target="`link-row-${data.item.id}-duplicate-icon`"
                placement="left"
              />

              <feather-icon
                :id="`link-row-${data.item.id}-edit-icon`"
                icon="EditIcon"
                size="16"
                class="mr-1 cursor-pointer"
                @click="$router.push({ name: 'header-edit', params: { id: data.item.id }})"
              />
              <b-tooltip
                title="Editar enlace"
                :target="`link-row-${data.item.id}-edit-icon`"
              />

              <div class="d-inline">
                <feather-icon
                  :id="`link-row-${data.item.id}-delete-icon`"
                  icon="TrashIcon"
                  size="16"
                  class="cursor-pointer"
                  @click="onDelete(data.item)"
                />
                <b-tooltip
                  title="Eliminar enlace"
                  :target="`link-row-${data.item.id}-delete-icon`"
                />
              </div>
            </div>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalLinks"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BTable,
  BCard,
  BCardTitle,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BPagination,
  BTooltip,
  BSpinner,
  // BImg,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import AppImage from '@core/components/app-image/AppImage.vue'
import useHeader from './useHeader'

export default {
  components: {
    BCard,
    BTable,
    BCardTitle,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BPagination,
    BTooltip,
    BSpinner,
    // BImg,
    AppImage,
  },
  setup() {
    const {
      fetchLinks,
      linkDelete,
      linkDuplicate,
      tableColumns,
      perPage,
      currentPage,
      totalLinks,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
    } = useHeader()

    const isAddNewUserSidebarActive = ref(false)
    const linkEditable = ref(null)

    async function onDuplicate(link) {
      try {
        const result = await this.$swal({
          title: 'Duplicar',
          text: '¿Deseas duplicar el link?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: '¡Sí, duplicalo!',
          cancelButtonText: 'Cancelar',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })

        if (result.value) {
          // eslint-disable-next-line no-param-reassign
          link.loading = true

          await linkDuplicate(link.id)

          // eslint-disable-next-line no-param-reassign
          link.loading = false

          refetchData()

          this.$swal({
            icon: 'success',
            title: '¡Duplicado!',
            text: 'El link ha sido duplicado',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      } catch (error) {
        console.log(error)
      }
    }

    async function onDelete(link) {
      try {
        const result = await this.$swal({
          title: '¿Estas seguro?',
          text: '¡No podrás revertir esta acción!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '¡Sí, bórralo!',
          cancelButtonText: 'Cancelar',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })

        if (result.value) {
          // eslint-disable-next-line no-param-reassign
          link.loading = true
          await linkDelete(link.id)
          // eslint-disable-next-line no-param-reassign
          link.loading = false
          refetchData()

          this.$swal({
            icon: 'success',
            title: '¡Borrado!',
            text: 'El link ha sido borrado',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      } catch (error) {
        console.log(error)
      }
    }

    return {
      isAddNewUserSidebarActive,
      linkEditable,
      refListTable,

      tableColumns,
      sortBy,
      isSortDirDesc,
      perPage,
      currentPage,
      totalLinks,
      dataMeta,
      perPageOptions,
      searchQuery,
      fetchLinks,
      refetchData,
      onDelete,
      onDuplicate,
    }
  },
}
</script>
